<template>
  <div>
    <titleBar
      :title="'Moderation tools'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span class="icon">
                <!-- 
                <i class="fas fa-edit"></i -->
              </span>
              <span>Moderate</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <b-switch v-model="bot.approved" type="is-success">
            Approved (for listing)
            <b-tooltip type="is-dark" multilined label="Initial approval">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.featured" type="is-dark">
            Featured
            <b-tooltip type="is-dark" multilined label="">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.modHidden" type="is-warning">
            hidden (only user can see)
            <b-tooltip type="is-dark" multilined label="">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.modOff" type="is-danger">
            Disable (off)
            <b-tooltip type="is-dark" multilined label="bye">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.verifiedbadge" type="is-info">
            Verified badge
            <b-tooltip type="is-dark" multilined label="Display a blue tick next to your page name in listings.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
            <span class="tag is-warning is-light">Premium</span>
          </b-switch>
        </b-field>

        <b-field horizontal label="handle">
          <b-input size="is-" placeholder="myAiProject" v-model="bot.handle"></b-input>
        </b-field>
        TODO: use a custom path renamer method that updates the references in all users (follows.)

        <b-field horizontal label="Bio">
          <b-input
            type="textarea"
            placeholder="Hi!  I chat and I know things."
            v-model="bot.bio"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label="Owner">
          <b-taginput v-model="bot.owner" ellipsis placeholder=" bob, steve, catey" aria-close-label="Delete this tag">
          </b-taginput>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.showInListing" type="is-info">
            NSFW
            <b-tooltip
              type="is-dark"
              multilined
              label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.hideBotOwner" type="is-info">
            Hide owners
            <b-tooltip type="is-dark" multilined label="For secret bots.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal label="cover URL ">
          <b-input
            size="is-"
            placeholder="https://myapp.com/img/logo.png"
            v-model="bot.cover"
            :loading="posting"
            :disabled="posting"
            type="url"
          ></b-input>
        </b-field>
        <b-field horizontal v-if="bot.cover">
          <img :src="bot.cover" style="width: 350px; height: 150px; border-radius: 50%" />
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
